// Generated by Framer (450d190)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["DSpniBDZw", "l83i2qm5H"];

const serializationHash = "framer-5fpfx"

const variantClassNames = {DSpniBDZw: "framer-v-1335qyg", l83i2qm5H: "framer-v-5udlw2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "DSpniBDZw", "Variant 2": "l83i2qm5H"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "DSpniBDZw"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "DSpniBDZw", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1stxkci = activeVariantCallback(async (...args) => {
await delay(() => setVariant("l83i2qm5H"), 3000)
})

const onAppeartrz3e2 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("DSpniBDZw"), 100)
})

useOnVariantChange(baseVariant, {default: onAppear1stxkci, l83i2qm5H: onAppeartrz3e2})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1335qyg", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"DSpniBDZw"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({l83i2qm5H: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-7dxbgz"} data-border layoutDependency={layoutDependency} layoutId={"IGKiXpCCs"} style={{"--border-bottom-width": "2px", "--border-color": "var(--token-c119737e-7f7f-422a-aff9-1c2ead345a86, rgb(255, 189, 168))", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", borderBottomLeftRadius: 53, borderBottomRightRadius: 53, borderTopLeftRadius: 53, borderTopRightRadius: 53}} variants={{l83i2qm5H: {borderBottomLeftRadius: 1, borderBottomRightRadius: 1, borderTopLeftRadius: 1, borderTopRightRadius: 1}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5fpfx.framer-1s4wwbz, .framer-5fpfx .framer-1s4wwbz { display: block; }", ".framer-5fpfx.framer-1335qyg { height: 20px; overflow: hidden; position: relative; width: 20px; }", ".framer-5fpfx .framer-7dxbgz { flex: none; height: 20px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; will-change: var(--framer-will-change-override, transform); }", ".framer-5fpfx.framer-v-5udlw2 .framer-7dxbgz { height: 2px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-5fpfx[data-border=\"true\"]::after, .framer-5fpfx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"l83i2qm5H":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerhLWWPmBev: React.ComponentType<Props> = withCSS(Component, css, "framer-5fpfx") as typeof Component;
export default FramerhLWWPmBev;

FramerhLWWPmBev.displayName = "eye";

FramerhLWWPmBev.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerhLWWPmBev, {variant: {options: ["DSpniBDZw", "l83i2qm5H"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerhLWWPmBev, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})